import { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import RazorPayPG from "views/PGComponents/RazorPayPG";
import { parse } from "querystring";
import { Spinner } from "react-bootstrap";
import { globalVars } from "Config";

const ProcessingPage = () => {
  const [searchParams] = useSearchParams();
  const [pgDetails, setPGDetails] = useState({})
  const { PAYMENTS_SERVICE_URL } = globalVars

  useEffect(() => {
    const onLoad = async () => {
      try {
        const result = await axios.post(`${PAYMENTS_SERVICE_URL}/open/api/v1/payment-orders?${searchParams.toString()}`);
        if (result?.data?.success) {
          const { payload } = result.data
          setPGDetails(payload)
        }
      } catch (e) {
        console.error(e);
      }
    };

    onLoad()
  }, [searchParams, PAYMENTS_SERVICE_URL]);

  const showPG = useCallback((pgD: { gateway_type?: string; sdk_invocation_details?: object; }) => {
    const { gateway_type, sdk_invocation_details } = pgD
    let PG = null
    switch (gateway_type) {
      case "RAZORPAY":
        PG = <RazorPayPG
          data={sdk_invocation_details}
          queryParams={parse(searchParams.toString())}
        />
        break
      case "MONERIS":

        break;
      default:
        PG = <div className="spinner-container">
          <Spinner animation="border" variant="primary" />
          <div>Please don't hit back button or refresh</div>
        </div>;
    }
    return PG
  }, [searchParams])

  return showPG(pgDetails);
};

export default ProcessingPage;
